.Avatar {
	border-radius: 50%;
	margin: auto;
	width: 8rem;
	height: 8rem;
	border: 1px solid salmon;
	background-position: 50% 20%;
	background-size: cover;
	background-repeat: no-repeat;
}

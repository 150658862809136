.Button {
	outline: none;
	background: linear-gradient(90deg, salmon 0%, rgba(186, 0, 0, 1) 100%);
	color: white;
	width: 10rem;
	height: 4rem;
	border: none;
	border-radius: 15px;
	box-shadow: 2px 2px 6px rgb(65, 65, 65);
	transition: all .1s ease-in-out;
}

.Button:hover {
	cursor: pointer;
	box-shadow: 2px 2px 16px rgba(186, 0, 0, 1);
}

.Button:disabled,
.Button:hover:disabled {
	background: rgb(85, 85, 85);
	color: white;
	font-weight: bold;
	box-shadow: none;
	cursor: not-allowed;
}

.Form {
	background: rgb(255, 238, 236);
	color: rgb(85, 85, 85);
	font-weight: bold;
	box-shadow: 2px 2px 3px rgb(65, 65, 65);
}
.Form:hover {
	cursor: pointer;
	box-shadow: 2px 2px 16px salmon;
}
.Form:active {
	transform: translateY(4px);
}

.Form:hover:disabled,
.Form:disabled {
	background: rgb(85, 85, 85);
	color: white;
	font-weight: bold;
	box-shadow: none;
}

.Button.Edit {
	background: white;
	align-self: flex-end;
	color: salmon;
	box-shadow: 0 0 1px 3px salmon;
}

.Button.Edit:hover,
.Button.Edit:hover:enabled {
	background: salmon;
	color: white;
}

.Button.Delete,
.Button.Delete:active,
.Button.Delete:focus,
.Button.Delete:focus:enabled {
	outline: none;
	background: linear-gradient(90deg, salmon 0%, rgba(186, 0, 0, 1) 100%);
	color: white;
	width: 10rem;
	height: 4rem;
	border: none;
	border-radius: 15px;
	box-shadow: 2px 2px 6px rgb(65, 65, 65);
	transition: all .1s ease-in-out;
}

.Button.Delete:hover,
.Button.Delete:hover:enabled {
	cursor: pointer;
	box-shadow: 2px 2px 16px rgba(186, 0, 0, 1);
}

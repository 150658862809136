.BurgerIcon {
	width: 3rem;
	height: 1.5rem;
	background: transparent;
	border: none;
	display: flex;
	outline: none;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.BurgerIcon span {
	width: 1.8rem;
	height: 4px;
	background-color: salmon;
	border-radius: 5px;
}

@media screen and (min-width: 992px) {
	.BurgerIcon {
		display: none;
	}
}

.Intro {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	margin: 6rem auto 0;
}

.ContentIntro {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	font-size: .8rem;
	height: fit-content;
	text-align: center;
	background: transparent;
	box-sizing: border-box;
	order: 1;
	box-sizing: border-box;
}

.ContentIntro * {
	margin: .5rem auto;
}

.ContentIntro h2 {
	line-height: 100%;
	color: rgb(85, 85, 85);
	width: 100%;
	font-size: 1rem;
	color: salmon;
}

.ContentIntro cite {
	font-size: 1rem;
	color: rgb(134, 134, 134);
}

.ImageIntro {
	width: 100%;
	padding-top: 15rem;
	order: 2;
	box-sizing: border-box;
	position: relative;
}

.ImageIntro img {
	width: 12rem;
	height: auto;
	position: absolute;
	top: 10%;
	left: 25%;
}

@media screen and (min-width: 400px) {
	.Intro {
		margin: 6rem auto 2rem;
	}
	.ContentIntro h2 {
		font-size: 1.3rem;
	}
	.ContentIntro cite {
		font-size: 1.2rem;
	}
	.ImageIntro img {
		width: 17rem;
		left: 20%;
	}
}

@media screen and (min-width: 768px) {
	.Intro {
		flex-direction: row;
		margin: 6rem auto 4rem;
	}

	.ContentIntro {
		order: 2;
		padding: 2rem 4rem;
		padding-left: 0;
		text-align: start;
		width: 50%;
	}
	.ContentIntro * {
		margin: .5rem;
	}
	.ContentIntro h2 {
		line-height: 100%;
		color: rgb(85, 85, 85);
		width: 100%;
		font-size: 2.1vw;
	}
	.ImageIntro {
		order: 1;
		width: 50%;
	}
	.ImageIntro img {
		width: 60%;
		height: auto;
		top: -3rem;
		right: 0rem;
	}
}

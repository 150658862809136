.ListItems {
	list-style: decimal;
	color: salmon;
	font-size: 1.5rem;
	width: fit-content;
	text-align: start;
	margin: 0 0 0 3rem;
	font-weight: bold;
}

.ListItems li {
	margin: 1.5rem 0;
}

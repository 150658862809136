.ProdukJasaDetailsContent {
	width: 85%;
	margin: 2rem auto;
}
.ProdukJasaDetailsContent > p {
	text-align: justify;
	font-size: 1rem;
	line-height: 150%;
}
.ProdukJasaDetailsContent ol {
	width: 100%;
	margin: 2rem auto;
	box-sizing: border-box;
	font-size: 1.2rem;
}
.ProdukJasaDetailsContent li {
	margin: 1rem auto;
}

@media screen and (min-width: 768px) {
	.ProdukJasaDetailsContent {
		width: 75%;
		margin: 2rem auto;
	}
	.ProdukJasaDetailsContent > p {
		font-size: 1.2rem;
		line-height: 150%;
	}
	.ProdukJasaDetailsContent li {
		margin: 1rem auto;
	}
	.ProdukJasaDetailsContent ol {
		width: 100%;
		margin: 2rem auto;
		box-sizing: border-box;
		font-size: 1.2rem;
	}
}

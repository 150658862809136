.ClientLogo {
	height: auto;
	width: auto;
	margin: 0.5rem;
}

.ClientLogo img {
	width: 100%;
	height: 100%;
}

@media screen and (min-width: 768px) {
	.ClientLogo img {
		width: 1000px;
		height: 100%;
	}
	.ClientLogo {
		margin: 1rem;
	}
}

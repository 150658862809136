.ArticleCarousel {
	width: 97%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.ArticleCarousel * {
	outline: none;
}

.ArticleCarousel > h3 {
	color: salmon;
	font-size: 30px;
}

.IntiDinamis button,
.IntiDinamis button:hover:enabled,
.IntiDinamis button:focus,
.IntiDinamis button:focus:enabled {
	background-color: salmon;
	box-shadow: 0 0 1px 3px rgba(186, 0, 0, 1);
	color: rgba(186, 0, 0, 1);
}

.IntiDinamis button:disabled,
.IntiDinamis button:hover:disabled {
	background-color: rgb(85, 85, 85);
	box-shadow: 0 0 1px 3px white;
	color: white;
}

.IntiDinamis button:hover,
.IntiDinamis button:hover:enabled {
	background-color: rgba(186, 0, 0, 1);
	box-shadow: none;
	color: white;
}

@media screen and (min-width: 992px) {
	.ArticleCarousel {
		width: 80%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
}

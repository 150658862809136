.HowCanWeHelp {
	display: flex;
	flex-direction: column;
	background: transparent;
	width: 100%;
	justify-content: space-around;
	margin-bottom: 4rem;
}

.ContentHowCanWeHelp {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	box-sizing: border-box;
	font-size: 1.3rem;
	margin-bottom: 1rem;
}

.ContentHowCanWeHelp > * {
	margin: 1rem auto;
}

.ContentHowCanWeHelp h3,
.ContentHowCanWeHelp p {
	width: 100%;
	text-align: center;
	color: rgb(85, 85, 85);
	font-size: 1rem;
}
.ContentHowCanWeHelp h3 {
	font-size: 1rem;
}

.ContentHowCanWeHelp p {
	margin-top: 0;
	line-height: 150%;
}

.ImageHowCanWeHelp {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	box-sizing: border-box;
	padding: 0;
}
.ImageHowCanWeHelp > img {
	width: 100%;
	height: auto;
}

.ButtonHowCanWeHelp {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 400px) {
	.ContentHowCanWeHelp h3,
	.ContentHowCanWeHelp p {
		font-size: 1.15rem;
	}
	.ContentHowCanWeHelp h3 {
		font-size: 1.3rem;
	}
}

@media screen and (min-width: 992px) {
	.HowCanWeHelp {
		flex-direction: row;
	}
	.ImageHowCanWeHelp {
		width: 50%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		box-sizing: border-box;
		padding-right: 15rem;
	}
	.ContentHowCanWeHelp {
		width: 50%;
		display: flex;
		flex-direction: column;
		padding: 0 6rem;
		box-sizing: border-box;
		font-size: 1.3rem;
	}
	.ContentHowCanWeHelp h3,
	.ContentHowCanWeHelp p {
		width: 100%;
		text-align: start;
		color: rgb(85, 85, 85);
		font-size: 1.3 rem;
	}
	.ButtonHowCanWeHelp {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.ProdukJasaNav {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: salmon;
	font-weight: bold;
	font-family: 'Trebuchet MS', sans-serif;
	box-sizing: border-box;
}

.ProdukJasaNav a {
	text-decoration: none;
	color: white;
	height: 100%;
	display: flex;
	align-items: center;
	padding: .3rem 1rem;
	box-sizing: border-box;
	transition: all .1s ease-in;
	width: 100%;
	justify-content: center;
}

.ProdukJasaNav a:focus,
.ProdukJasaNav a:hover,
.ProdukJasaNav a:active,
.ProdukJasaNav a.active {
	color: rgb(85, 85, 85);
	background: rgb(255, 238, 236);
	width: 100%;
}

@media screen and (min-width: 900px) {
	.ProdukJasaNav {
		flex-direction: row;
		flex-wrap: wrap;
		margin: inherit;
		padding: 0 1rem;
		font-size: .9rem;
	}
	.ProdukJasaNav a {
		padding: .5rem 1rem;
		width: initial;
	}
	.ProdukJasaNav a:focus,
	.ProdukJasaNav a:hover,
	.ProdukJasaNav a:active,
	.ProdukJasaNav a.active {
		width: initial;
	}
}

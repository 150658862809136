.GaleriList {
	display: flex;
	flex-wrap: wrap;
	padding: 2rem;
	width: 100%;
	height: auto;
	margin: 1rem auto;
	box-sizing: border-box;
	justify-content: space-around;
}

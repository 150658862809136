.WA_email {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	border: none;
	border-radius: 30px 0 0 30px;
	position: fixed;
	right: 0;
	top: 45%;
	background-color: salmon;
	width: 3rem;
	height: 6rem;
	box-shadow: 0 0 8px black;
	font-size: 3rem;
	transition: all .1s ease-in;
	box-sizing: border-box;
	z-index: 5;
}

.WA_email a > * {
	height: 100%;
}

.WA_email > a {
	height: 50%;
	display: flex;
	align-items: center;
}

.WA_email:hover {
	box-shadow: 0 0 8px salmon;
}

.WA_email .WhatsAppIcon,
.WA_email .MailOutlineIcon {
	color: white;
	font-size: 2rem;
}

.WA_email .WhatsAppIcon:hover,
.WA_email .MailOutlineIcon:hover {
	color: maroon;
	cursor: pointer;
}

@media screen and (min-width: 768px) {
	.WA_email {
		width: 3rem;
		height: 6rem;
		border-radius: 20px 0 0 20px;
	}
	.WA_email .WhatsAppIcon,
	.WA_email .MailOutlineIcon {
		font-size: 2rem;
	}
	.WA_email > a {
		height: 100%;
	}
}

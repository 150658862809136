.ContentDesc {
	width: 100%;
	height: 350px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: rgb(85, 85, 85);
	box-sizing: border-box;
	padding: 4rem auto;
	position: relative;
}

.ContentDesc > h1 {
	font-size: 2.5rem;
	margin: 1rem;
}

.ContentDesc > p {
	font-size: 1.5rem;
	margin: 0;
}

.ContentDescBackground {
	position: absolute;
	opacity: .35;
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;
	top: 0;
	left: 0;
	width: 100%;
	height: 350px;
	z-index: -20;
}

.ContentDescDescription {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 1rem 1.5rem;
	box-sizing: border-box;
	background-color: transparent;
	font-size: 1.3rem;
	line-height: 200%;
}

.DescriptionContainer {
	margin: 1rem auto;
}

.WhiteGradient {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -19;
	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 40%);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
	.ContentDescDescription {
		padding: 1rem 5rem;
	}
}
@media screen and (min-width: 1200px) {
	.ContentDescDescription {
		padding: 1rem 15rem;
	}
}

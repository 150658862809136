body {
	margin: 0 auto;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
	font-family: sans-serif;
	margin-top: 5rem;
	text-align: center;
}

.modal-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.modal-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms;
}

.modal-exit {
	transform: translateY(0);
	opacity: 1;
}

.modal-exit-active {
	transform: translateY(-10rem);
	opacity: 0;
	transition: all 200ms;
}

.drawer-enter {
	transform: translateX(10rem);
	opacity: 0;
}

.drawer-enter-active {
	transform: translateX(0);
	opacity: 1;
	transition: all 400ms;
}

.drawer-exit {
	transform: translateX(0);
	opacity: 1;
}

.drawer-exit-active {
	transform: translateX(10rem);
	opacity: 0;
	transition: all 400ms;
}

.HubungiKami {
	width: 100%;
	height: 5rem;
	background-color: salmon;
	color: white;
	margin: 7rem auto 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.MainHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	background-color: white;
	padding: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	transition: all .5s ease-in-out;
}

.Scrolling {
	background-color: rgba(255, 238, 236, .9);
}

@media screen and (min-width: 992px) {
	.MainHeader {
		padding: .5rem 2rem;
	}
}

.PartnerList {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.PartnerList > h1 {
	margin: 3rem auto 0;
	color: salmon;
}

.PartnerListUnordered {
	list-style: none;
	box-sizing: border-box;
	margin: 0 auto;
	width: 90%;
	padding: 0;
}

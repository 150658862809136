.ArticleEditForm {
	list-style: none;
	margin: 3rem auto;
	padding: 1rem;
	width: 60%;
	max-width: 40rem;
	background-color: white;
	border-radius: 6px;
	box-shadow: 0 2px 6px rgb(85, 85, 85);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ArticleEditForm button {
	align-self: flex-end;
	margin: 1rem 1rem 1rem 0;
}

.CopyrightBar {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: salmon;
	height: 3rem;
}

.CopyrightBar footer {
	color: white;
}

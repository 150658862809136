.Address {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: rgb(85, 85, 85);
	background-color: transparent;
}

.Address > * {
	margin: 0;
	line-height: 150%;
}

.Address > h3 {
	font-size: 1.4rem;
}

.Address > p {
	font-size: 1rem;
}

@media screen and (min-width: 400px) {
	.Address > p {
		font-size: 1.2rem;
	}
}

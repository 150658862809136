.Articles {
	width: 90%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
	margin: 2rem auto;
	box-sizing: border-box;
}

.Articles > * {
	margin: 2rem 1rem;
}

.ContactUs {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 0 2rem;
	justify-content: space-around;
	align-items: center;
}

.ContactUs > * {
	width: 100%;
}

@media screen and (min-width: 992px) {
	.ContactUs {
		flex-direction: row;
	}
	.ContactUs > * {
		width: 50%;
	}
}

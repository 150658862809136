.ContactLink {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
	margin: 0;
	box-sizing: border-box;
	margin-top: 2rem;
}

.Email,
.Phone {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	color: rgb(85, 85, 85);
}
.Email svg,
.Phone svg {
	font-size: 2rem;
}

.Email > *,
.Phone > * {
	margin: 0 .5rem;
	font-size: 1.2rem;
}

@media screen and (min-width: 992px) {
	.Email,
	.Phone {
		font-size: 1.5rem;
	}
	.Email svg,
	.Phone svg {
		font-size: 4rem;
	}
}

.Modal {
	z-index: 100;
	position: fixed;
	top: 22vh;
	left: 10%;
	width: 80%;
	background: white;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 8px;
}

.ModalHeader {
	width: 100%;
	padding: 1rem 0.5rem;
	background: salmon;
	color: white;
	box-sizing: border-box;
	border-radius: 8px 8px 0 0;
}

.ModalHeader h2 {
	margin: 0.5rem;
}

.ModalContent {
	padding: 1rem 0.5rem;
}

.ModalFooter {
	padding: 1rem 0.5rem;
	display: flex;
	justify-content: flex-end;
	position: relative;
}

.ModalFooter > * {
	margin: 0 .5rem;
}

@media (min-width: 768px) {
	.Modal {
		left: calc(50% - 20rem);
		width: 40rem;
	}
}

.AdminAuthForm {
	display: flex;
	flex-direction: column;
	width: 40%;
	min-width: 25rem;
	margin: 2rem auto;
	padding: 1rem;
	box-shadow: 2px 2px 6px rgb(85, 85, 85);
	box-sizing: border-box;
	border: 2px solid rgb(85, 85, 85);
	border-radius: 20px;
}

.Switch {
	width: 100%;
	text-align: center;
	color: salmon;
	font-weight: bold;
}

.Switch:hover {
	cursor: pointer;
	text-shadow: 0 0 10px salmon;
}

.ButtonContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: .5rem auto;
}

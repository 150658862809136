.TentangKamiBrief {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 90%;
	font-size: 1.4rem;
	line-height: 180%;
	margin: 3rem auto;
}

.TentangKamiBrief a {
	text-decoration: none;
	color: inherit;
}

.TentangKamiBrief h2 {
	margin-bottom: 0;
	color: salmon;
	font-size: 1rem;
}
.TentangKamiBrief p {
	font-size: 1rem;
	margin: .5rem auto 1rem;
	line-height: 2rem;
}

.TentangKamiBrief label {
	margin-top: .5rem;
}

.TentangKamiBriefAvatar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}
.TentangKamiBriefAvatar > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 1rem auto;
}

@media screen and (min-width: 400px) {
	.TentangKamiBrief h2 {
		font-size: 1.4rem;
	}
	.TentangKamiBrief p {
		line-height: 2rem;
	}
}

@media screen and (min-width: 992px) {
	.TentangKamiBrief {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 65%;
		font-size: 1.4rem;
		line-height: 180%;
		color: rgb(85, 85, 85);
	}
	.TentangKamiBrief h2 {
		margin-bottom: 0;
		color: salmon;
		font-size: 30px;
	}
	.TentangKamiBrief p {
		font-size: 1.5rem;
		margin: 1.8rem auto;
		line-height: 2rem;
	}
}
@media screen and (min-width: 550px) {
	.TentangKamiBriefAvatar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		width: 100%;
	}

	.TentangKamiBriefAvatar > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin: auto;
	}
}

.PartnerProfile {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	margin: 2rem auto;
	flex-direction: column;
}

.PartnerPhoto {
	width: 50%;
	border-radius: 35px;
}
.PartnerPhoto img {
	width: 65%;
	height: auto;
	max-width: 100%;
	border-radius: 35px;
	box-shadow: 2px 2px 6px black;
}

.PartnerDesc {
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.PartnerDesc > p {
	text-align: center;
}

.PartnerProfile:nth-child(even) .PartnerDesc {
	order: 2;
}
.PartnerProfile:nth-child(even) .PartnerPhoto {
	order: 1;
}

.PartnerDesc > h2 {
	font-size: 1.5rem;
	margin: 0;
	color: rgb(102, 8, 8);
}

.PartnerDesc > p:nth-of-type(1) {
	font-size: 1.2rem;
	width: 60%;
	margin: 0;
}
.PartnerDesc > p:nth-of-type(2) {
	font-size: 1.1rem;
	width: 90%;
	color: rgb(85, 85, 85);
	line-height: 150%;
}

@media screen and (min-width: 992px) {
	.PartnerPhoto img {
		width: 55%;
	}
	.PartnerProfile {
		flex-direction: row;
		margin: 4rem auto;
	}
	.PartnerDesc {
		width: 50%;
		margin-top: 0;
		align-items: flex-start;
	}
	.PartnerDesc > p {
		text-align: start;
	}
	.PartnerProfile:nth-child(even) .PartnerDesc {
		align-items: flex-end;
	}

	.PartnerProfile:nth-child(even) .PartnerDesc > p {
		text-align: end;
	}
	.PartnerProfile:nth-child(even) .PartnerDesc {
		order: 1;
	}
	.PartnerProfile:nth-child(even) .PartnerPhoto {
		order: 2;
	}
}
@media screen and (min-width: 1200px) {
	.PartnerPhoto img {
		width: 40%;
	}
}

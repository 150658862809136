.NavLinks {
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0;
}

.NavLinks li {
	margin: .5rem;
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
}

.NavLinks a {
	text-decoration: none;
	color: rgb(85, 85, 85);
	transition: all .1s ease-in-out;
}

.NavLinks a:hover,
.NavLinks a:active,
.NavLinks a.active {
	color: rgb(250, 0, 0);
	text-shadow: 2px 2px 8px salmon;
}

@media screen and (min-width: 992px) {
	.NavLinks {
		flex-direction: row;
	}
	.NavLinks li {
		margin: 1rem;
		text-align: center;
		font-size: inherit;
		font-weight: normal;
		margin: 0 .7rem;
	}
}

.Input {
	width: 100%;
	padding: .5rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Label {
	font-weight: bold;
	display: block;
	margin-bottom: .3rem;
	margin-left: .3rem;
	align-self: flex-start;
}

.InputElement {
	outline: none;
	border: none;
	border-radius: 10px;
	background-color: rgb(255, 238, 236);
	font: inherit;
	padding: 6px 10px;
	display: block;
	width: 100%;
	box-sizing: border-box;
}

.InputElement:focus {
	outline: none;
	background-color: rgb(196, 196, 196);
}

.Invalid p,
.Invalid label {
	color: red;
	align-self: flex-start;
}
.Invalid p {
	margin: 0;
	margin-top: .5rem;
	padding-left: .5rem;
}

.Invalid input,
.Invalid textarea {
	border-color: red;
	background-color: salmon;
}

.Invalid input::placeholder,
.Invalid textarea::placeholder {
	color: white;
}

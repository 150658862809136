.SideDrawer {
	position: fixed;
	top: 0;
	right: 0;
	width: 60%;
	height: 100vh;
	z-index: 100;
	background-color: white;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.26);
}

.GoogleLocation {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 70%;
	margin: 3rem auto;
	box-sizing: border-box;
}

.GoogleLocation > div {
	width: 50%;
	text-align: center;
}

.GoogleMaps {
	border: 2px salmon solid;
	width: 80%;
}

.GoogleAddress {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: rgb(85, 85, 85);
}

.GoogleAddress > * {
	margin: 0;
	font-size: 1.3rem;
}

.GoogleAddress > h3 {
	margin-bottom: 1.5rem;
}

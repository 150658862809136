.GaleriFrame {
	width: 100%;
	margin: 1rem .5rem;
	border-radius: 20px;
}

.GaleriFrame img {
	width: 100%;
	height: auto;
	border-radius: 20px;
	box-shadow: 2px 2px 6px black;
}

@media screen and (min-width: 768px) {
	.GaleriFrame {
		width: 45%;
	}
}

.Logo {
	background-color: transparent;
	padding: 2px;
	height: 100%;
	box-sizing: border-box;
	border-radius: 5px;
}

.Logo img {
	height: auto;
}

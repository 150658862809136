.ProdukJasaCard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18rem;
	height: 18rem;
	border-radius: 20px;
	box-sizing: border-box;
	box-shadow: 2px 2px 6px black;
	position: relative;
	margin: 3rem 1rem;
	transition: all .1s ease-in-out;
}

.ProdukJasaCard:hover {
	cursor: pointer;
	box-shadow: 0 0 18px maroon;
}

.ProdukJasaCard:hover > h2 {
	opacity: 0;
}

.ProdukJasaCard > h2 {
	font-size: 2rem;
	font-style: italic;
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode',
		Geneva, Verdana, sans-serif;
	color: white;
	padding: 2rem;
	transition: all .5s ease-in-out;
}

.ProdukJasaBackground {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -20;
	filter: brightness(40%);
}

.ProdukJasaBackgroundColor {
	border-radius: 20px;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -19;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, rgba(250, 128, 114, 1) 100%);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.ProdukJasaBrief {
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	top: 0;
	left: 0;
	padding: 1.5rem;
	font-size: 1rem;
	text-align: start;
	color: white;
	transform: translateY(50%);
	transition: all .5s ease-in-out;
	opacity: 0;
	z-index: -10;
	line-height: 1.6rem;
}

.ProdukJasaCard:hover > .ProdukJasaBrief {
	transform: translateY(0);
	z-index: 0;
	opacity: 1;
}

@media screen and (min-width: 768px) {
	.ProdukJasaCard {
		margin: 3rem 1rem;
		width: 20rem;
		height: 20rem;
		flex-wrap: wrap;
	}
}

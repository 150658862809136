.ContactForm {
	width: 90%;
	margin: 2rem auto;
}

@media screen and (min-width: 992px) {
	.ContactForm {
		width: 30%;
		margin: 4rem auto;
	}
}

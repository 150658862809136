.ProdukJasaList {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 2rem;
	padding-top: 0;
	box-sizing: border-box;
}

.ProdukJasaList a {
	text-decoration: none;
	color: inherit;
	box-sizing: border-box;
	margin: 0 auto;
}

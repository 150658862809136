.ArticleContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 90%;
	margin: auto;
}

.Article {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	padding: 1rem;
	box-sizing: border-box;
}

.Article h1 {
	border-bottom: 1px solid rgb(85, 85, 85);
	width: 100%;
	padding-bottom: .5rem;
	margin-bottom: 0;
	font-size: 1.2rem;
}

.Article p {
	text-align: justify;
	line-height: 150%;
	width: 100%;
}

.Article cite {
	width: 100%;
	text-align: end;
	margin: 2rem 0;
}

.ArticleFeatured {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: rgba(85, 85, 85, .5);
	font-style: italic;
	box-sizing: border-box;
	margin-bottom: 2rem;
}

.ArticleFeatured > h3 {
	border-bottom: 1px solid rgb(85, 85, 85);
	width: 100%;
}

.ArticleFeatured a {
	text-decoration: none;
	color: rgb(85, 85, 85);
	width: 100%;
	text-align: start;
	margin: 0 auto 1rem;
}
.ArticleFeatured a:hover {
	color: salmon;
}

@media screen and (min-width: 768px) {
	.ArticleContainer {
		justify-content: space-between;
		flex-direction: row;
		width: 80%;
		align-items: start;
	}
	.Article {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 70%;
		padding: 1rem;
		box-sizing: border-box;
	}
	.Article h1 {
		border-bottom: 1px solid rgb(85, 85, 85);
		width: 100%;
		padding-bottom: .5rem;
		margin-bottom: 0;
		font-size: 1.5rem;
	}
	.Article p {
		text-align: start;
	}
	.ArticleFeatured {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		width: 20%;
		margin: 1.5rem;
	}
}

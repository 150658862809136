.ArticleForm {
	list-style: none;
	margin: 3rem auto;
	padding: 1rem;
	width: 90%;
	max-width: 40rem;
	background-color: white;
	border-radius: 6px;
	box-shadow: 0 2px 6px rgb(85, 85, 85);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.SubmitBtn {
	margin: 1rem auto;
	padding-right: 1rem;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	box-sizing: border-box;
}

.ArticleCard {
	display: flex;
	flex-direction: column;
	border-radius: 25px;
	box-shadow: 2px 2px 6px rgb(85, 85, 85);
	width: 17rem;
	height: 18rem;
	outline: none;
	text-decoration: none;
	color: inherit;
	font-size: .8rem;
	position: relative;
}

.ArticleCard a {
	text-decoration: none;
	color: inherit;
	display: flex;
	flex-direction: column;
}

.ArticleImg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 33%;
	border-radius: 25px 25px 0 0;
}

.ArticleContent {
	width: 100%;
	height: 100%;
	padding: .7rem;
	text-align: start;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	box-sizing: border-box;
}

.ArticleContent a {
	word-wrap: break-word;
}
.ArticleContent h4 {
	margin: 0;
}
.ArticleContent p {
	line-height: 150%;
	margin: 0;
	margin-top: .5rem;
	justify-self: flex-start;
	height: 100%;
}

.ButtonGroup {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.ButtonGroup > * {
	margin: 0 .5rem;
}

.spinnerContainer {
	position: absolute;
	height: 2rem;
	top: -80%;
	right: 5%;
}

.spinnerContainer > * {
	margin: 0;
	font-size: 2rem;
}

.ReadMore {
	position: absolute;
	bottom: 1rem;
	left: 1rem;
}
.ReadMore:hover {
	color: salmon;
}

@media screen and (min-width: 400px) {
	.ArticleCard {
		font-size: 1rem;
		width: 17rem;
		height: 25rem;
	}
	.ArticleContent {
		padding: 1rem;
	}
}

@media screen and (min-width: 992px) {
	.ArticleCard {
		display: flex;
		flex-direction: column;
		border-radius: 25px;
		box-shadow: 2px 2px 6px rgb(85, 85, 85);
		width: 20rem;
		height: 27rem;
		outline: none;
		text-decoration: none;
		color: inherit;
	}

	.ArticleContent {
		line-height: 150%;
	}
}

.Footer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 2rem;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	background-color: rgb(255, 238, 236);
}

.Footer p {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.Footer {
		flex-direction: row;
	}
}

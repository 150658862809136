.MisiIntiDinamis {
	width: 100%;
	background-color: salmon;
	height: 15rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	margin: 0 auto;
	box-sizing: border-box;
}

.MisiIntiDinamis > h2 {
	font-size: 2rem;
	width: 100%;
}

.MisiIntiDinamis > p {
	font-size: 1.2rem;
	margin-top: 0;
	line-height: 150%;
	width: 90%;
	font-style: italic;
}

@media screen and (min-width: 768px) {
	.MisiIntiDinamis > p {
		font-size: 1.5rem;
		margin-top: 0;
		line-height: 150%;
		width: 50%;
	}
}
@media screen and (min-width: 1200px) {
	.MisiIntiDinamis > p {
		font-size: 1.5rem;
		margin-top: 0;
		line-height: 150%;
		width: 50%;
	}
}
